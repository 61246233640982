import { useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import 'cleave.js/dist/addons/cleave-phone.us'

import { FormGroup, Label, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import _ from "lodash"

import { trans } from '@utils'
import { ButtonSpinner, ErrorMessages } from '@src/components'

import {
  _editTicket,
  _getAllStatusesWithQForEdit,
  _getAllPrioritiesWithQ,
  _getAllDepartmentsWithQ,
  _getAllCampaignsWithQ
} from "../../redux/actions"
import AsyncSelect from "react-select/async"

const BasicInfoModal = (props) => {

  const loading = useSelector(state => state.app.loading)
  const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
  const [isEditAction, setIsEditAction] = useState(props.data.id)
  const [open, setOpen] = useState(true)
  const [department, setDepartment] = useState('')
  const [priority, setPriority] = useState('')
  const [status, setStatus] = useState('')
  const [blogCampaign, setBlogCampaign] = useState([])
  const [valErrors, setValErrors] = useState({})
  const [defaultTier, setDefaultTier] = useState(_.get(props, 'props.data.is_default') ?? false)
  const [ticketData, setTicketData] = useState({
    id: props.data.id,
    ticket_subject: props.data.ticket_subject,
    initial_message: props.data.initial_message,
    campaign_id: props.data.campaign_id,
    department_id: props.data.department_id,
    user_id: props.data.user_id,
    priority_id: props.data.priority_id,
    admin_id: props.data.admin_id,
    status_id: props.data.status_id,
    attachments: props.data.attachments
    // created_by: _.get(props, 'props.data.created_by') ? {label: _.get(props, 'props.data.created_by.name'), value: _.get(props, 'props.data.created_by.id')} : '',

  })
  const categories = [
    { value: 'fashion', label: 'Fashion' },
    { value: 'gaming', label: 'Gaming' },
    { value: 'quote', label: 'Quote' },
    { value: 'video', label: 'Video' },
    { value: 'food', label: 'Food' }
  ]

  const _close = () => {
    setOpen(false)
    props.onClose()
    if (props.setRefresh) {
      props.setRefresh(!props.refresh)
    }
  }

  const onSubmit = (data) => {
    trigger()
    setTicketData(prevState => {
      _editTicket(
        {
          ...prevState,
          ...data,
          id: props.data.id,
          department_id: data.department_id.value,
          campaign_id: data.campaign_id.value,
          priority_id: data.priority_id.value,
          status_id: data.status_id.value
        },
        () => {
          //console.log(attachment)
          _close()
          props.successCallback()
        },
        (err) => {
        }
      )
    })
  }
  const _getAllCampaigns = () => {
    return _getAllCampaignsWithQ(props.data.user_id)
  }

  return (
    <Modal
      isOpen={open}
      toggle={_close}
      unmountOnClose={true}
      backdrop={true}
      className='sidebar-lg'
      contentClassName='p-0'
      modalClassName='modal-slide-in sidebar-todo-modal'
    >
      <Form action='/' className='flex-grow-1 d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={_close} className='mb-1'>
          {isEditAction ? 'Edit Ticket' : 'Add Ticket'}
        </ModalHeader>
        <ModalBody className='flex-grow-1 pb-sm-0 pb-3 modal-body'>

          <FormGroup>
            {/* <Label className='form-label' for='blog-edit-campaign'>
              Campaign
            </Label> */}
            {/* <Controller
              as={AsyncSelect}
              control={control}
              name={'campaign_id'}
              id='campaign_id'
              isClearable={false}
              isMulti={false}
              classNamePrefix='select'
              defaultOptions
              cacheOptions
              loadOptions={_getAllCampaigns}
              className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
              defaultValue={props.data.campaign_id ? { label: props.data.campaign.name, value: props.data.campaign_id } : ''}
            /> */}

            <Label className='form-label' for='blog-edit-department'>
              Department
            </Label>
            <Controller
              as={AsyncSelect}
              control={control}
              name={'department_id'}
              id='department_id'
              isClearable={false}
              isMulti={false}
              classNamePrefix='select'
              defaultOptions
              cacheOptions
              loadOptions={_getAllDepartmentsWithQ}
              className={classnames({ 'is-invalid': errors['name'] || _.get(props.data.valErrors, 'name') })}
              defaultValue={props.data.department_id ? { label: props.data.tickets_department.department_name, value: props.data.department_id } : ''}
            />

            <Label className='form-label' for='blog-edit-priority'>
              Priority
            </Label>
            <Controller
              as={AsyncSelect}
              control={control}
              name={'priority_id'}
              id='priority_id'
              isClearable={false}
              isMulti={false}
              classNamePrefix='select'
              defaultOptions
              cacheOptions
              loadOptions={_getAllPrioritiesWithQ}
              className={classnames({ 'is-invalid': errors['name'] || _.get(props.data.valErrors, 'name') })}
              defaultValue={props.data.priority_id ? { label: props.data.tickets_priority.name, value: props.data.priority_id } : ''}
            />
            <Label className='form-label' for='blog-edit-status'>
              Status
            </Label>
            <Controller
              as={AsyncSelect}
              control={control}
              name={'status_id'}
              id='status_id'
              isClearable={false}
              isMulti={false}
              classNamePrefix='select'
              defaultOptions
              cacheOptions
              loadOptions={_getAllStatusesWithQForEdit}
              className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
              defaultValue={props.data.status_id ? { label: props.data.tickets_status.name, value: props.data.status_id } : ''}
            />

            <ErrorMessages valErrors={props.valErrors} errors={errors} name={'status_id'} />
          </FormGroup>

        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <Button.Ripple type='submit' className='flex-grow-1' color='primary' disabled={loading}>
            {loading ? <ButtonSpinner /> : null}
            <span>{trans('gen.actions.save')}</span>
          </Button.Ripple>
          <Button.Ripple type='button' outline className='flex-grow-1' color='secondary' disabled={loading} onClick={_close}>
            <span>{trans('gen.actions.cancel')}</span>
          </Button.Ripple>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default BasicInfoModal

