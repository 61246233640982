import { UncontrolledTooltip } from 'reactstrap'

export const TruncatedTextComponent = ({ id, text, maxLength = 20 }) => {
  const truncatedText = text.substring(0, maxLength) + (text.length > maxLength ? '...' : '')
  const fullText = text

  return (
    <span id={`tooltip-${id}`}>
      <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
        {fullText}
      </UncontrolledTooltip>
      {truncatedText}
    </span>
  )
}